<template>
  <div class="sld_order_list">
    <MemberTitle memberTitle="渠道申请"></MemberTitle>
    <div class="warp2">
      <h3>渠道申请</h3>
      <div class="title_search">
        <el-form  :show-overflow-tooltip="true"  :inline="true" :model="formInline" class="title_search_form"  size="mini" >
          <el-form-item class="type_text" :inline="true" label="商品名称:">
            <el-input v-model="contactName"  placeholder="请输入商品名称"></el-input>
          </el-form-item>
          <!-- <el-form-item class="type_text" label="电话:">
            <el-input v-model="contactPhone" placeholder="请输入电话" ></el-input>
          </el-form-item> -->
          <div class="title_search_time">
            <el-form-item label="发起时间:" size="mini" class="type_time">
              <el-date-picker  style="width: 210px"  v-model="search_time"  type="daterange"   start-placeholder="开始时间"    end-placeholder="结束时间"  >
              </el-date-picker>
            </el-form-item>
          </div>
          <el-form-item label="申请状态" class="type_selit">
            <el-select v-model="state" placeholder="申请状态">
              <el-option v-for="item in stateList"  :label="item.label"  :value="item.value" :key="item.value" ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onSubmit" class="title_subit"
              >搜索</el-button
            >
            <el-button type="primary" @click="onReset" class="title_Reset">重置</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- 下方结构表头 -->
      <div class="bhn"  style="width:100%;">
        <div class="select_name" style="font-weight: 700;margin-left: 50px">商品信息</div>
         <div class="good_price" style="font-weight: 700; margin-left: 70px">价格</div>
        <div class="num" style="font-weight: 700">渠道价</div>
      
        <div class="state" style="font-weight: 700 ;margin-left: 50px">申请时间</div>
        <div class="oprate" style="font-weight: 700">申请状态</div>
       <div class="oprate" style="font-weight: 700;margin-right: 50px" >操作</div>
      </div>
      <!-- 下方结构表格 -->
      <block >
        <div class="order_item" v-for="(item, index) in order_list" :key="index"  >
          <!--商品信息  -->
           <div class="order_gg">{{index+1}}</div>
          <!-- 价格 -->
          <div class="order_gg phone">
               <img :src="item.mainImage" class="left" alt="">
               <div class="right">
                   <div class="tyu">{{item.goodsName}}</div>
                   <div class="tyu">{{item.categoryPath}}</div>
               </div>
          </div>
          <!--渠道价  -->
         <div class="order_gg draw">{{item.productPrice}}</div>
          <!-- 图片 -->
          <div class="order_gg imges" v-if="item.state==2">{{item.channelPrice}} </div>
          <!--时间 -->
          <div class="order_gg time">{{item.createTime}}</div>
          <!--状态  -->
          <div class="order_gg statess" :class="item.stateValue=='审核失败'?'states':''">  {{item.stateValue}} <br> {{item.failReason}}</div>
          <!-- 操作 -->
          <!-- <div class="order_gg do"    v-if="item.state == 3"   @click="goEdit(item.inquiryId)" > 重新发布 </div>
          <div class="order_gg do1" v-else-if="item.state == 1" @click="cancellation(item.inquiryId)">
            关闭
          </div> -->
          <!-- <div class="order_gg do1" v-else-if="item.state == 4"></div>
          <div class="order_gg do" @click="getmsg(item.inquiryId)" v-else>
            查看回复
          </div> -->
           <div class="order_gg do"  v-if="item.stateValue=='待审核'"  @click="getmsg1(item.applyId)" >
                 取消
          </div>
            <div class="order_gg do"  v-if="item.stateValue=='审核成功'"   >
                
          </div>
           <div class="order_gg do"  v-if="item.stateValue=='审核失败'"    @click="getmsg(item.applyId)" >
                 再次申请
          </div>
        </div>
        <!-- <el-pagination
          layout="prev, pager, next, jumper"
          :page-size="100"
          :total="pageData.total"
          :hide-on-single-page="true"
          class="flex_row_end_center"
        ></el-pagination> -->
        <el-pagination @prev-click="handlePrevCilickChange"  @size-change="handleSizeChange"  @next-click="handleNextCilickChange"  @current-change="handleCurrentChange"  :current-page="pageData.current"   :page-sizes="[1, 2, 4, 6]"  :page-size="pageData.pageSize" layout="total, sizes, prev, pager, next, jumper"  :total="pageData.total"  class="flex_row_end_center" ></el-pagination>
      </block>

      <block v-if="iuy">
        <SldCommonEmpty :tip="'暂无订单～'" totalWidth="74px"
      /></block>
    </div>
    <el-dialog
      title="回复"
      v-model="dialogVisible"
      width="823px"
      :before-close="handleClose"
    >
      <div>
        <ul class="infinite-list" style="overflow: auto">
          <li v-for="(items, indexs) in msgList" :key="indexs" class="hasmsg">
            <div class="msg_title">
              <div>{{ items.factoryName }}</div>
              <div>{{ items.deliveryTime }}</div>
            </div>
            <div class="msg_main">
              <div class="msg_main_title">
                <div>价格：${{ items.goodsPrice }}</div>
                <div>定金比例：{{ items.depositRatio }}%</div>
                <div>期望交货日：{{ items.deliveryTime }}</div>
              </div>
              <div class="msg_main_cont">
                <text>回复内容：</text>
                <div class="msg_content">{{ items.replyContent }}</div>
              </div>
              <div class="msg_main_img">
                <text>图片：</text>
                <div
                  class="img_fa"
                  v-for="(itx, idxx) in items.replyImages"
                  :key="idxx"
                >
                  <img :src="itx" alt="" />
                </div>
              </div>
            </div>
            <div class="bten" @click="luyong(items.replyId)">录用</div>
          </li>
        </ul>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, getCurrentInstance, onMounted, reactive } from "vue";
import MemberTitle from "../../../components/MemberTitle";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import SldCommonEmpty from "../../../components/SldCommonEmpty";
export default {
  components: {
    MemberTitle,
    SldCommonEmpty,
  },
  setup() {
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    const stateList = ref([
      {
        value: "1",
        label: "待审核",
      },
      {
        value: "2",
        label: "正常",
      },
      {
        value: "3",
        label: "审核拒绝",
      }
     
    ]); //状态数组
    const search_time = ref(""); //顶部选择的时间
    const contactName = ref(""); //联系人姓名
    const contactPhone = ref(""); //联系人电话
    const state = ref(""); //询价单状态
    const order_list = ref([]); //订单列表
    const pageData = reactive({
      current: 1,
      pageSize: 6,
      total: 0,
    });
    const dialogVisible = ref(false); //查看回复弹窗
    const msgList = ref([]); //查看回复弹窗
    //组件时间处理
    const playTime = (time) => {
      var date = new Date(time);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let times = y + "-" + m + "-" + d; //这里如果不需要小时 分  后边的可以不需要拼接
      return times;
    };
    //     搜索点击事件
    const onSubmit = () => {
    //    contactName  商品名称  search_time 发起时间  state 状态
    console.log(contactName.value,search_time.value,state.value)
      let param = {};
      param.current = pageData.current;
      param.pageSize = pageData.pageSize; //分页
     //开始时间 和 结束时间
      if (search_time.value) {
        let startTime = playTime(search_time.value[0]);
        let endTime = playTime(search_time.value[1]);
        if (startTime) {
          param.startTime = startTime;
        }
        if (endTime) {
          param.endTime = endTime;
        }
      }
      //做的商品名称为空判断
      if (contactName.value) {
        param.goodsName = contactName.value;
      }
      //状态值是否选中
      if (state.value) {
        param.state = state.value;
      }

      proxy.$get("/v3/goods/front/goodsChannelApply/list", param).then((res) => {
        console.log(res);
        if (res.state == 200) {
          order_list.value = res.data.list;
          pageData.total = res.data.pagination.total;
        } else {
          ElMessage(res.msg);
        }
      });
    };
    //点击重置按钮事件
    const onReset = () => {
      //       console.log(contactName, contactPhone, state);
      contactName.value = "";
      contactPhone.value = "";
      state.value = "";
      search_time.value = "";
    };
    //获取订单列表
    const getOrderList = () => {
      let param = {};
      param.pageSize = 6;
      proxy.$get("/v3/goods/front/goodsChannelApply/list", param).then((res) => {
        console.log(res);
        if (res.state == 200) {
           console.log(res)
          order_list.value = res.data.list;
          pageData.total = res.data.pagination.total;
        } else {
          ElMessage(res.msg);
        }
      });
    };
    //跳转重新编辑
    const goEdit = (inquiryId) => {
      router.push({
        path: "/member/xuenjia/fabuxuenjia",
        query: {
          inquiryId: inquiryId,
        },
      });
    };
    //点击条每页事件事件
    const handleSizeChange = (index) => {
      pageData.pageSize = index;
      onSubmit();
    };
    //向前翻页
    const handlePrevCilickChange = (e) => {
      pageData.current = e;
      onSubmit();
    };
    //向后翻页
    const handleNextCilickChange = () => {
      pageData.current++;
      onSubmit();
    };
    //页数改变
    const handleCurrentChange = (current) => {
      pageData.current = current;
      onSubmit();
    };
    //取消订单
    const cancellation = (inquiryId) => {
      ElMessageBox.confirm("关闭询价?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          proxy
            .$post("/v3/goods/front/goods/inquiry/close", {
              inquiryId,
            })
            .then((res) => {
              if (res.state == 200) {
                ElMessage.success("关闭询价成功");
                onSubmit();
              } else {
                ElMessage(res.msg);
              }
            })
            .catch(() => {
              //异常处理
            });
        })
        .catch(() => {});
    };
    //  点击再次申请
    const getmsg = (inquiryId) => {
      let form = {};
      form.applyId = inquiryId;
      proxy.$post("/v3/goods/front/goodsChannelApply/edit", form).then((res) => {
        console.log(res);
        if (res.state == 200) {
          ElMessage(res.msg);
        } else {
          ElMessage(res.msg);
        }
      });
    };
   //点击取消
const getmsg1 = (inquiryId) => {
      let form = {};
      form.applyId = inquiryId;
      proxy.$post("/v3/goods/front/goodsChannelApply/del", form).then((res) => {
        console.log(res);
        if (res.state == 200) {
              ElMessage(res.msg);
        } else {
          ElMessage(res.msg);
        }
      });
    };
    //     点击录用事件
    const luyong = (replyId) => {
      proxy
        .$post("/v3/goods/front/goods/inquiry/confirmEmploy", { replyId })
        .then((res) => {
          console.log(res);
          if (res.state == 200) {
            router.push({
              path: "/buy/comformorder",
              query: {
                replyId: replyId,
              },
            });
          } else {
            ElMessage(res.msg);
          }
        });
    };
    onMounted(() => {
      getOrderList();
    });
    return {
      order_list,
      stateList,
      contactName,
      contactPhone,
      state,
      search_time,
      pageData,
      dialogVisible,
      msgList,
      getOrderList,
      onSubmit,
      onReset,
      playTime,
      goEdit,
      handleSizeChange,
      handlePrevCilickChange,
      handleNextCilickChange,
      handleCurrentChange,
      cancellation,
      getmsg,
      getmsg1,
      luyong,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/ApplicationList.scss";
</style>
